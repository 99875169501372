<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
            <b-button
              variant="primary"
              @click="export2excel"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1">
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
            <b-button
              :to="{
                name:
                  'apps-' +
                  $t(this.RESOURCES_ELEMENT_NAME + '.module_name') +
                  '-create',
              }"
              variant="primary">
              <span class="text-nowrap">{{
                $t(RESOURCES_ELEMENT_NAME + '.object.add')
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
        <!-- column: name -->
        <template #cell(name)="data">
          <b-badge
            pill
            class="text-capitalize">
            {{ data.item.value }}
          </b-badge>
        </template>
        <!-- Column: key -->
        <template #cell(id)="data">
          {{ data.item.key }}
        </template>
        <!-- Column: Status -->
        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-col class="flex flex-row flex-wrap">
            <b-button
              style="margin: 1px"
              variant="primary"
              :id="get_view_button_id(data.item.id)"
              :to="{
                name:
                  'apps-' +
                  $t(RESOURCES_ELEMENT_NAME + '.module_name') +
                  '-view',
                params: { id: data.item.id },
              }">
              <feather-icon icon="FileTextIcon" />
            </b-button>

            <b-button
              style="margin: 1px"
              variant="primary"
              :id="get_edit_button_id(data.item.id)"
              v-if="show_editable(data.item.editable)"
              :to="{
                name:
                  'apps-' +
                  $t(RESOURCES_ELEMENT_NAME + '.module_name') +
                  '-edit',
                params: { id: data.item.id },
              }">
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              style="margin: 1px"
              variant="primary"
              :id="get_delete_button_id(data.item.id)"
              v-if="show_editable(data.item.editable)"
              @click="delete_entity(data.item)">
              <feather-icon icon="DeleteIcon" />
            </b-button>

            <!-- ? DETAILS -->
            <b-tooltip
              :target="get_view_button_id(data.item.id)"
              :title="$t('actions.details')"
              triggers="hover"
              :noninteractive="true"
              placement="top">
            </b-tooltip>
            <!-- ? EDIT -->
            <b-tooltip
              :target="get_edit_button_id(data.item.id)"
              :title="$t('actions.edit')"
              triggers="hover"
              :noninteractive="true"
              placement="top">
            </b-tooltip>
            <!-- ? DELETE -->
            <b-tooltip
              :target="get_delete_button_id(data.item.id)"
              :title="$t('actions.delete')"
              triggers="hover"
              :noninteractive="true"
              placement="top">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div>
      <b-modal
        id="modal-1"
        hide-footer
        :title="$t('actions.delete')">
        <template>
          <delete-confirmation
            :value-name="currentSelection.value"
            :resources-element-name="RESOURCES_ELEMENT_NAME"
            class="mt-2 pt-75" />
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BTooltip,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Filters from './filters.vue'
import DeleteConfirmation from '../../core/DeleteConfirmation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from '../storeModule'
import * as XLSX from 'xlsx'

export default {
  components: {
    DeleteConfirmation,
    Filters,
    BTooltip,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      TYPE: '',
      SEARCH_TYPE: '',
      DEEP: 1,
      PARENT_ID: undefined,
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      currentSelection: {},
      tableColumns: [],
      sheets: [{ name: 'SheetOne', data: [{ c: 2 }] }],
    }
  },
  beforeDestroy() {
    this.$root.$off('delete-entity')
  },
  mounted() {
    if (router.currentRoute.params.deep) {
      this.DEEP = router.currentRoute.params.deep
    }
    this.PARENT_ID = router.currentRoute.params.parent_id
    this.TYPE = router.currentRoute.params.type
    this.SEARCH_TYPE = router.currentRoute.params.search_type
    this.RESOURCES_ELEMENT_NAME = this.TYPE
    this.ELEMENT_APP_STORE_MODULE_NAME = 'app-ent-li-' + this.TYPE
    this.tableColumns = [
      {
        key: 'key',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.id'),
        sortable: true,
      },
      {
        key: 'value',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.name'),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.actions'),
        thStyle: 'width: 300px;',
      },
    ]
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )
    var data = JSON.parse(sessionStorage.getItem(this.currentPath))

    data && (this.query = data.query)
    this.fetchElements()

    this.$root.$on('delete-entity', () => {
      var _self = this
      store
        .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/delete_element', {
          id: _self.currentSelection.id,
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t('actions.result.delete.title'),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t('actions.result.delete.success'),
            },
          })
          _self.fetchElements()
        })
        .catch((error) => {
          _self.fetchElements()
          // _self.$toast({
          //   component: ToastificationContent,
          //   position: 'top-right',
          //   props: {
          //     title: _self.$t('actions.result.delete.title'),
          //     icon: 'SaveIcon',
          //     variant: 'danger',
          //     text: _self.$t('actions.result.delete.failure'),
          //   },
          // })
        })
    })
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  watch: {
    perPage: function (val) {
      this.fetchElements()
    },
    currentPage: function (val) {
      this.fetchElements()
    },
    query: function (val) {
      this.currentPage = 1
      this.fetchElements()
    },
  },
  methods: {
    get_view_button_id(key) {
      return 'view_button' + key
    },
    get_edit_button_id(key) {
      return 'edit_button' + key
    },
    get_delete_button_id(key) {
      return 'delete_button' + key
    },
    delete_entity(selected_element) {
      console.log(selected_element)
      this.currentSelection = selected_element
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
    show_editable(is_editable) {
      return is_editable === 1
    },
    filterJson(jsonData) {
      var filterList = this.tableColumns.map(function (a) {
        return a.key
      })
      filterList.pop()
      return jsonData.map((v) =>
        filterList.map((j) => {
          return v[j]
        })
      )
    },
    getHeader() {
      const res = {}
      for (var i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    format(jsonData) {},
    fetchElements() {
      sessionStorage.setItem(
        this.currentPath,
        JSON.stringify({ query: this.query })
      )

      store
        .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_elements', {
          type: this.SEARCH_TYPE,
          queryParams: {
            parent: this.PARENT_ID,
            deep: this.DEEP,
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
        })
        .catch(() => {})
    },
    export2excel() {
      var _self = this
      const filename = this.$t(this.RESOURCES_ELEMENT_NAME + '.object_name')
      store
        .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_elements', {
          type: this.SEARCH_TYPE,
          queryParams: {
            parent: this.PARENT_ID,
            deep: this.DEEP,
            perPage: 10000,
            page: 1,
            query: this.query,
          },
        })
        .then((result) => {
          _self.format(result.data.items)
          result.data.items.unshift(_self.getHeader())
          let list = _self.filterJson(result.data.items)
          let data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
          const workbook = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(workbook, data, filename)
          XLSX.writeFile(workbook, `${filename}.xlsx`)
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
